const faqs = [
  {
    question:
      "Quais são as linhas de Crédito Imobiliário? E qual a diferença entre elas?",
    answer: [
      {
        text: "Hoje nós temos o Crédito Imobiliário e o Crédito Imobiliário com Juros da Poupança."
      },
      {
        text: "Para o Crédito Imobiliário com taxa pré-fixada, a nossa taxa é a partir de 12,29% ao ano + TR (Taxa Referencial) e é uma linha de crédito em que a taxa não tem variação e se mantém fixa do início ao fim do contrato."
      },
      {
        text: "Já para o Crédito Imobiliário com Juros da Poupança, a sua taxa é pós-fixada e varia de acordo com o rendimento da poupança. O rendimento da poupança pode diminuir ou aumentar e quando a taxa Selic diminui, você paga menos juros e sua parcela fica mais baixa e quando a Selic aumenta, para sua segurança, há um limite de aumento em sua taxa e parcelas."
      }
    ]
  },
  {
    question: "Como eu simulo o meu financiamento e envio uma proposta?",
    answer: [
      {
        text: (
          <span>
            <span aria-hidden="true">
              Você pode realizar a sua simulação e enviar sua proposta{" "}
            </span>
            <a
              className="faq__link"
              href="/proposta"
              target="_blank"
              rel="noreferrer"
              aria-label="clicando aqui"
            >
              clicando aqui
            </a>
            <span aria-hidden="true">.</span>
          </span>
        )
      },
      {
        text: "O Simulador está preparado para imóveis residenciais e comerciais e você pode escolher entre duas linhas de crédito, sendo elas: Crédito Imobiliário com taxa Pré fixada e Crédito imobiliário juros poupança, além disso, você pode escolher entre dois sistemas de amortização:"
      },
      {
        text: "1-)  SAC – Sistema de Amortização Constante "
      },
      {
        text: "2-) MIX – Sistema Misto de Amortização (para essa modalidade, é necessário escolher a linha de crédito convencional)."
      },
      {
        text: "Após o resultado da simulação você pode enviar uma proposta para análise de crédito, sem compromisso, do qual será necessário preencher alguns dados complementares. No Itaú, você tem a possibilidade de indicar uma segunda pessoa proponente, ou seja, uma segunda pessoa que irá financiar o imóvel com você. "
      }
    ]
  },
  {
    question: "Em quanto tempo eu recebo a análise de crédito?",
    answer: [
      {
        text: "O prazo de resposta depende do valor do financiamento desejado. Para financiamentos com valor de até R$ 1,5 milhão, o retorno acontece em até 1h e para imóveis acima desse valor, pode ser em até 1 dia útil."
      }
    ]
  },
  {
    question: "Consigo alterar a linha de crédito depois de enviar a proposta?",
    answer: [
      {
        text: "Sim, durante a contratação, antes da etapa de confirmar os valores e emitir o seu contrato você pode solicitar a pessoa consultora que alterne entre as linhas de crédito que oferecemos, mas lembre-se que qualquer alteração de valores ou da modalidade dos juros implicará em uma nova análise de crédito."
      },
      {
        text: "Agora, se depois que você finalizar a contratação surgir essa necessidade, você também pode verificar se seu contrato é elegível à migração e qual é a taxa disponível através da Central de Atendimento do Crédito Imobiliário através dos números, 4004 4828 para capitais, regiões metropolitanas e celulares ou 0800 970 4828 para demais localidades."
      },
      {
        text: "O horário de atendimento é das 8h às 20h de segunda à sexta-feira."
      }
    ]
  },
  {
    question: "Qual é o tempo máximo de financiamento?",
    answer: [
      {
        text: "Você pode contratar o seu financiamento em até 35 anos para imóveis residenciais e em até 20 anos para imóveis comerciais. Todos os prazos estão sujeitos à análise de crédito."
      }
    ]
  },
  {
    question: "Qual é o valor mínimo e máximo que eu posso contratar?",
    answer: [
      {
        text: "Para imóveis residenciais, o valor mínimo do imóvel deve ser de R$ 100 mil e o valor mínimo de financiamento de R$ 30 mil. Não há valor máximo do imóvel, porém o valor de financiamento não pode ser maior do que 90% do valor do imóvel."
      },
      {
        text: "Para Imóveis comerciais o valor mínimo é de R$ 100 mil, sem limite máximo e o valor mínimo de financiamento é de R$ 50 mil. Não há valor máximo do imóvel, desde que o financiamento não ultrapasse 50% do valor do imóvel."
      },
      {
        text: "Os percentuais de financiamento estão sujeitos à análise de crédito."
      }
    ]
  },
  {
    question: "Qual é o valor mínimo de entrada?",
    answer: [
      {
        text: "Para imóveis residenciais você deverá ter, no mínimo, 10% do valor de avaliação do imóvel e para comerciais a entrada mínima é de 50%. Todos os valores estão sujeitos à análise de crédito."
      }
    ]
  },
  {
    question: "Eu preciso comprovar renda?",
    answer: [
      {
        text: "Durante a análise de crédito será necessário comprovar ou atualizar a sua renda aqui conosco. Se necessário, podemos solicitar alguns documentos como demonstrativo da folha de pagamento, Imposto de Renda, entre outros."
      }
    ]
  },
  {
    question: "Posso compor renda com outra pessoa?",
    answer: [
      {
        text: "Sim, se você estiver comprando o imóvel com alguém, você também poderá usar a renda da pessoa que está participando do financiamento para comprovar o valor necessário para a aprovação de crédito."
      },
      {
        text: "Lembre-se de que se você for casado ou conviva em união estável a outra pessoa do financiamento deverá ser, obrigatoriamente, o seu cônjuge/companheiro."
      }
    ]
  },
  {
    question: "Ainda não escolhi o meu imóvel, posso começar o financiamento?",
    answer: [
      {
        text: "Sim. Você pode preencher seus dados para análise de crédito e, após a aprovação, negociar as condições do financiamento. A validade da sua carta de crédito é de 60 dias, a partir da data de aprovação, para efetuar toda a contratação, após o vencimento, será necessário realizar uma nova análise de crédito."
      }
    ]
  },
  {
    question: "Quais as regras de elegibilidade para contratar o Pula Parcela?",
    answer: [
      {
        text: "Ser correntista Itaú, estar em dia com o pagamento do seu contrato, não estar com processo de portabilidade em andamento, não estar utilizando o FGTS para pagamento de parte de suas parcelas no momento e não estar com o pagamento da próxima parcela já agendado, ou seja, entre a data da contratação do Pula Parcela e a data do vencimento da sua próxima parcela deve ter no mínimo 5 dias úteis de diferença."
      },
      {
        text: "Além disso, você não pode ter uma operação de sinistro MIP (Morte ou Invalidez Permanente) em andamento ou em processo de análise e o seu contrato tem que ter, no mínimo, mais 7 parcelas restantes."
      },
      {
        text: "Lembre-se que o benefício só pode ser utilizado uma vez a cada 12 meses, independente do número de parcelas que você escolheu pular, e se você tiver aderido ao programa Travessia/Carência terá que esperar, pelo menos, 12 meses a partir da data da última parcela incorporada."
      },
      {
        text: "Consulte se o esse benefício está disponível para você e se o seu contrato está elegível diretamente no App Itaú ;)"
      }
    ]
  }
]

export { faqs }
