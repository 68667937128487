import { IS_RECAPTCHA_ENTERPRISE_ENABLED, tokens } from "gatsby-env-variables"

export const loadScriptCaptcha = () => {
  const sitekey = tokens.RECAPTCHA
  const script = document.createElement("script")
  script.src = `https://www.google.com/recaptcha/${
    IS_RECAPTCHA_ENTERPRISE_ENABLED ? "enterprise" : "api"
  }.js?render=${sitekey}`
  document.body.appendChild(script)
}

export const executeRecaptcha = async () => {
  if (!window?.grecaptcha) {
    console.error("@google/reCAPTCHA - unable to initiliaze")
  }

  if (IS_RECAPTCHA_ENTERPRISE_ENABLED) {
    await window?.grecaptcha.enterprise.ready(() =>
      console.info("@google/reCAPTCHA - ready"),
    )
  } else {
    await window?.grecaptcha.ready(() =>
      console.info("@google/reCAPTCHA - ready"),
    )
  }

  const method = IS_RECAPTCHA_ENTERPRISE_ENABLED
    ? window?.grecaptcha.enterprise.execute
    : window?.grecaptcha.execute

  const action = IS_RECAPTCHA_ENTERPRISE_ENABLED ? "lead_start" : "submit"

  return await method(tokens.RECAPTCHA, {
    action,
  })
}
